{
  "name": "caloudi-ng-csp-cmp-crp",
  "version": "17.7.5",
  "private": true,
  "engineStrict": true,
  "engines": {
    "node": "^v20.0.0 || ^v22.0.0",
    "npm": ">=10.0.0",
    "typescript": "~5.4.0"
  },
  "scripts": {
    "prestart": "npm outdated & node -v & npm -v & ng lint --cache --fix",
    "start": "ng serve --configuration=cmp-dev-serve",
    "preysl-start": "npm outdated & npm update & ng lint --cache --fix",
    "ysl-start": "ng serve --configuration=ysl-dev-serve",
    "update": "npm update & npm audit fix & npm prune",
    "build": "ng build --configuration=cmp-dev",
    "test": "ng test --configuration=cmp-test-dev",
    "lint": "ng lint --format=unix > issues.log",
    "eslintconfig": "npx eslint --print-config src\\main.ts",
    "analyz-dev": "env NODE_ENV=analyz npm_config_report=true ng build --configuration=analyz-dev",
    "analyz-prod": "env NODE_ENV=analyz npm_config_report=true ng build --configuration=analyz-prod",
    "prod-test": "env NG_BUILD_MANGLE=true NG_BUILD_MINIFY=true NG_BUILD_BEAUTIFY=true ng serve --port=4201 --configuration=cmp-prod",
    "dev-test": "ng serve --poll=2000 --port=4201 --configuration=cmp-dev",
    "preysl-prod-build": "node src/initial.js & ng lint --quiet --fix --force",
    "preysl-dev-build": "node src/initial.js & ng lint --quiet --fix --force",
    "precmp-prod-build": "node src/initial.js & ng lint --fix",
    "precmp-dev-build": "node src/initial.js & ng lint --fix",
    "precmp-qa-build": "node src/initial.js & ng lint --fix --force",
    "ysl-prod-build": "ng build --configuration=ysl-prod",
    "ysl-dev-build": "ng build --configuration=ysl-dev",
    "cmp-prod-build": "ng build --configuration=cmp-prod",
    "cmp-dev-build": "ng build --configuration=cmp-dev",
    "cmp-dev-aws-build": "ng build --configuration=cmp-dev-aws",
    "cmp-test-build": "ng build --configuration=cmp-test",
    "cmp-qa-build": "ng build --configuration=cmp-qa",
    "sass": "sass --update src/sass/primeng/layout:src/styles/layout src/sass/primeng/theme:src/styles/theme",
    "sass-layout": "sass --update src/sass/primeng/layout:src/styles/layout",
    "sass-theme": "sass --update src/sass/primeng/theme:src/styles/theme",
    "version": "npm outdated & node -v & npm -v"
  },
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^17.3.9",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/language-service": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@angular/material": "^17.3.9",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@babel/runtime": "^7.25.6",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "~6.6.0",
    "@fortawesome/free-brands-svg-icons": "~6.6.0",
    "@fortawesome/free-solid-svg-icons": "~6.6.0",
    "@microsoft/applicationinsights-web": "^3.3.2",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "@ngx-formly/bootstrap": "^6.3.6",
    "@ngx-formly/core": "^6.3.6",
    "@ngx-formly/primeng": "^6.3.6",
    "@ngx-translate/core": "^15.0.0",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.4",
    "cronstrue": "^2.50.0",
    "d3": "^7.9.0",
    "html2canvas": "^1.4.1",
    "jquery": "^3.7.1",
    "jquery-ui-dist": "^1.13.3",
    "js-md5": "~0.8.3",
    "js-sha256": "~0.11.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-gauge": "^10.0.0",
    "ngx-logger": "^5.0.12",
    "ngx-markdown": "^18.0.0",
    "ngx-page-scroll": "^13.0.0",
    "ngx-page-scroll-core": "^13.0.0",
    "ngx-permissions": "^17.1.0",
    "ngx-spinner": "^17.0.0",
    "pivottable": "^2.23.0",
    "plotly.js-dist-min": "^2.35.0",
    "powerbi-client": "^2.23.1",
    "powerbi-client-angular": "^3.0.5",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.9",
    "quill": "^2.0.2",
    "rxjs": "^7.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "^18.0.0-alpha.16",
    "@angular-eslint/eslint-plugin": "^18.0.0-alpha.16",
    "@angular-eslint/eslint-plugin-template": "^18.0.0-alpha.16",
    "@angular-eslint/schematics": "^18.0.0-alpha.16",
    "@angular-eslint/template-parser": "^18.0.0-alpha.16",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@babel/cli": "^7.25.6",
    "@babel/core": "^7.25.2",
    "@babel/plugin-transform-runtime": "^7.25.4",
    "@babel/preset-env": "^7.25.4",
    "@ngx-formly/schematics": "^6.3.6",
    "@nightwatch/schematics": "^1.2.1",
    "@stylistic/eslint-plugin": "^2.7.2",
    "@types/babel__core": "^7.20.5",
    "@types/d3": "^7.4.3",
    "@types/google.visualization": "~0.0.74",
    "@types/http-errors": "^2.0.4",
    "@types/jasmine": "^5.1.4",
    "@types/jquery": "^3.5.30",
    "@types/js-md5": "^0.7.2",
    "@types/karma": "^6.3.8",
    "@types/nightwatch": "^2.3.31",
    "@types/node": "^20.16.4",
    "@types/plotly.js-dist-min": "^2.3.4",
    "@types/quill": "^2.0.14",
    "@types/web": "~0.0.159",
    "@types/web-animations-js": "^2.2.16",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "@typescript-eslint/parser": "^7.18.0",
    "@typescript-eslint/types": "^7.18.0",
    "@typescript-eslint/utils": "^8.18.0",
    "axe-core": "^4.10.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-html": "^8.1.1",
    "eslint-plugin-import-newlines": "^1.4.0",
    "eslint-plugin-prettier": "^5.2.1",
    "jasmine-browser-runner": "^2.5.0",
    "jasmine-core": "^5.2.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ngrx-store-freeze": "~0.2.4",
    "nightwatch": "^3.7.0",
    "sass": "^1.78.0",
    "typescript": "~5.4.0",
    "typescript-eslint-language-service": "^5.0.5"
  },
  "peerDependencies": {
    "tslib": "^2.7.0"
  }
}